// contexts/ContextJbo.js
import { createContext, useContext, useState } from 'react';

// Creating a new context for Jbo
const JboContext = createContext();

/**
 * JboProvider component to provide the Jbo context to its child components.
 * 
 * This component wraps around parts of the application that need access to Jbo context.
 * It provides a 'jboId' value and a method to update it, allowing for a shared state 
 * across various components.
 * 
 * @param {React.ReactNode} children - The child components of JboProvider.
 * @param {string|number} initialJboId - The initial value for the jboId state.
 */
export const JboProvider = ({ children, initialJboId }) => {
  const [jboId, setJboId] = useState(initialJboId);// State for storing Jbo ID
  //console.log("jboIdcontext",jboId);
  // Providing the jboId and setJboId through the JboContext
  return (
    <JboContext.Provider value={{ jboId, setJboId }}>
      {children}
    </JboContext.Provider>
  );
}
/**
 * Custom hook to use the JboContext.
 * 
 * This hook provides an easy way to access and update the jboId within components.
 * It ensures that the context is used within a JboProvider.
 * 
 * @returns {Object} The JboContext object containing jboId and setJboId.
 */
export const useJobId = () => {
  const context = useContext(JboContext);
  if (context === undefined) {
    throw new Error('useJobId must be used within a JboProvider');
  }
  return context;
}
