const getBaseUrl = () => {
    
    if (typeof window !== "undefined") {
        const hostname = window.location.hostname;
        let baseUrl;
        if (hostname === "localhost") {
            baseUrl = `https://${process.env.NEXT_PUBLIC_WEB_HOST}/api`;
            // baseUrl = `http://192.168.1.97:4071/api`;
           
        } else {
            baseUrl = `https://${hostname}/api`;
        }
        return baseUrl;
    } else {
        return `https://${process.env.NEXT_PUBLIC_WEB_HOST}/api`;
    }
};

const getEnv = () => {
    if (typeof window !== "undefined") {
        const hostname = window.location.hostname;
        return hostname;
    }
};

const getBuilderUrl = () => {
    if (typeof window !== "undefined") {
        const hostname = window.location.hostname;
        let builderUrl;
        if (hostname === "localhost") {
            builderUrl = `https://${process.env.NEXT_PUBLIC_WEB_HOST}/api/v3`;
        } else {
            builderUrl = `https://${hostname}/api/v3`;
        }
        return builderUrl;
    } else {
        return "https://sitebuilder.jpencil.in/api/v3";
    }
};

const setImageSrc = (imageUrl, productName, dimension = 's400') => {
    const hostname = typeof window !== "undefined" ? window.location.hostname :  process.env.NEXT_PUBLIC_WEB_HOST ;
    const isExternalUrl = typeof imageUrl === 'string' && (imageUrl.startsWith('http://') || imageUrl.startsWith('https://'));
    const dimensionPrefix = dimension ? `${dimension}__` : '';
    
    if (hostname === "localhost") {
        if (isExternalUrl) {
            return imageUrl;
        } else {
            if (imageUrl !== null && imageUrl !== undefined) {
                return `https://${process.env.NEXT_PUBLIC_WEB_HOST}/product_image/${dimensionPrefix}${imageUrl}`;
            }
        }
    }
    else {
        if (isExternalUrl) {
            return imageUrl;
        } else {
            if (imageUrl !== null && imageUrl !== undefined) {
            return `https://${hostname}/product_image/${dimensionPrefix}${imageUrl}`;
            }
        }
    }
};

const setImageSrcNew = (imageUrl, productName, dimension = 's400') => {
    const hostname = typeof window !== "undefined" ? window.location.hostname :  process.env.NEXT_PUBLIC_WEB_HOST ;
    const isExternalUrl = typeof imageUrl === 'string' && (imageUrl.startsWith('http://') || imageUrl.startsWith('https://'));
    const dimensionPrefix = dimension ? `${dimension}__` : '';
    
    if (hostname === "localhost") {
        if (isExternalUrl) {
            return imageUrl;
        } else {
            if (imageUrl !== null && imageUrl !== undefined) {
                return `https://jpencil.ta4.in/product_image/${dimensionPrefix}${imageUrl}`;
            }
        }
    }
    else {
        if (isExternalUrl) {
            return imageUrl;
        } else {
            if (imageUrl !== null && imageUrl !== undefined) {
            return `/product_image/${dimensionPrefix}${imageUrl}`;
            }
        }
    }
};

//allow images only from these hostnames
const imagesConfig = () => {
    const hostname = typeof window !== "undefined" ? window.location.hostname : process.env.NEXT_PUBLIC_WEB_HOST ;
    const domains = [
        hostname,
    'dev.darjewellery.com',
    'qc.darjewellery.com',
    'www.darjewellery.com',
      'jpencil.ta4.in',
      'img.freepik.com',
      'media.ta4.in',
      'ik.imagekit.io',
      'dashboard2.jpencil.com',
      'jbo.tanjorepainting.info',
      'media2.tarangarts.com',
      'media.jpencil.in',
      'shop.jpencil.com',
      'via.placeholder.com',    
      'media.tarangarts.com',
      'static.tarangarts.com',
      'veritypc.com',
      '165.232.187.184',
      'media.jpencil.in',
      'undefined.undefined'
    ];
    return domains.filter(domain => domain !== undefined && domain !== 'undefined.undefined');
  };


// Export the base URL, builder URL, and image source function

module.exports = {
    BASE_URL: getBaseUrl(),
    BUILDER_URL: getBuilderUrl(),
    setImageSrc,
    setImageSrcNew,
    imagesConfig,
    _ENV : getEnv()
  };