import React, { createContext, useState, useEffect } from 'react';

// Creating a context for currency-related data
export const CurrencyContext = createContext();

/**
 * CurrencyProvider component to manage currency state and provide it to the rest of the application.
 * 
 * This component handles the current currency state, currency data including conversion rates, 
 * and provides a method to format currency values. It initializes currency from localStorage 
 * and updates it when the currency state changes.
 * 
 * @param {React.ReactNode} children - The child components of CurrencyProvider.
 */
export const CurrencyProvider = ({ children }) => {
  // Get the initial currency from localStorage or set it to 'USD' if it doesn't exist
  const initialCurrency = localStorage.getItem('currency') || 'INR';

  const [currentCurrency, setCurrentCurrency] = useState(initialCurrency);
  const [currencyData, setCurrencyData] = useState({
    INR: {
      name: 'INR',
      symbol: '\u20B9', // Unicode for ₹
      conversionRate: 1, // will be updated from the API
    },
    USD: {
      name: 'USD',
      symbol: '\u0024',
      conversionRate: 1, // will be updated from the API
    },
  });

  // Effect to update localStorage when the current currency changes
  useEffect(() => {
    localStorage.setItem('currency', currentCurrency);
  }, [currentCurrency]);

  // Function to format currency values based on the current currency
  function formatCurrency(amount) {
    amount = typeof amount === 'string' ? amount : String(amount);

    let formattedIntegerPart, decimalPart;

    if (currentCurrency === 'INR') {
      // Remove existing commas
      const formattedAmount = amount.replace(/,/g, '');
      [formattedIntegerPart, decimalPart] = formattedAmount.split('.');

      // Add commas every two digits from the right for INR
      formattedIntegerPart = formattedIntegerPart.replace(/(\d)(?=(\d{2})+\d$)/g, '$1,');
    } else if (currentCurrency === 'USD') {
      // Remove existing commas
      const formattedAmount = amount.replace(/,/g, '');
      [formattedIntegerPart, decimalPart] = formattedAmount.split('.');

      // Add commas every three digits from the right for USD
      formattedIntegerPart = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return amount;
    }

    return (
      <span className="space-x-1">
        <span className="currency-symbol">{currencyData[currentCurrency].symbol}</span>
        <span className="currency-amount  font-semibold">{formattedIntegerPart}{decimalPart ? '.' + decimalPart : ''}</span>
      </span>
    );
  }
 // Function to format currency values as a string
 function formatCurrencyString(amount) {
  amount = typeof amount === 'string' ? amount : String(amount);

  let formattedIntegerPart, decimalPart;

  if (currentCurrency === 'INR') {
    // Remove existing commas
    const formattedAmount = amount.replace(/,/g, '');
    [formattedIntegerPart, decimalPart] = formattedAmount.split('.');

    // Add commas every two digits from the right for INR
    formattedIntegerPart = formattedIntegerPart.replace(/(\d)(?=(\d{2})+\d$)/g, '$1,');
  } else if (currentCurrency === 'USD') {
    // Remove existing commas
    const formattedAmount = amount.replace(/,/g, '');
    [formattedIntegerPart, decimalPart] = formattedAmount.split('.');

    // Add commas every three digits from the right for USD
    formattedIntegerPart = formattedIntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return amount;
  }

  return `${currencyData[currentCurrency].symbol} ${formattedIntegerPart}${decimalPart ? '.' + decimalPart : ''}`;
}
  // Providing the currency context to child components
  return (
    <CurrencyContext.Provider value={{ currentCurrency, setCurrentCurrency, formatCurrency,formatCurrencyString, currencyData, setCurrencyData }}>
      {children}
    </CurrencyContext.Provider>
  );
};
