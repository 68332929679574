
import { createContext, useState, useEffect } from 'react';
// Creating a context for theme-related data
export const ThemeContext = createContext();

/**
 * ThemeProvider component to manage and provide theme settings to the application.
 * 
 * This component handles the application's dynamic theme, including colors and fonts.
 * It updates the CSS custom properties (variables) based on the provided theme.
 * 
 * @param {React.ReactNode} children - The child components of ThemeProvider.
 * @param {Object} theme - Object containing theme settings such as colors, fonts, etc.
 */

export function ThemeProvider({ children, theme }) {
//console.log("themeData",theme);
  const [dynamicTheme, setDynamicTheme] = useState({
    primary: theme ? theme.primary : "",
    secondary: theme ? theme.secondary : "",
    alternative: theme ? theme.alternative : "",
    secondaryFont: theme ? theme.secondaryFont : "",
    alternativeFont: theme ? theme.alternativeFont : "",
    font: theme ? theme.font : "",
    title:theme? theme.title : "",
    content:theme? theme.content:"",
    // currency:theme.currency,
    token:theme ? theme.token : "",
    // token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqYm9faWQiOjI0OSwic2l0ZV9zdGF0dXMiOjAsImlhdCI6MTcxMDU5MzYxOX0.M5anhjEb3RnZazpO5b-pMKJJKCT78OM6Gjag7lPDQ3Y",
    siteStatus:theme?theme.siteStatus : 0,
    splashLogo:theme ? theme.splashLogo :"",
    favicon:theme?theme.favicon:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtvkhdb4yEh54uzO6bCzALyZtRmlqlfKOmqA&usqp=CAU"
  });
 
    // Effect to update CSS custom properties when the theme changes
  useEffect(() => {
     // Setting CSS variables for theme colors and fonts
    document.documentElement.style.setProperty('--primary-color', dynamicTheme.primary);
    document.documentElement.style.setProperty('--secondary-color', dynamicTheme.secondary);
    document.documentElement.style.setProperty('--alternative-color', dynamicTheme.alternative);
    document.documentElement.style.setProperty('--font-family', dynamicTheme.font);
    document.documentElement.style.setProperty('--secondary-font-family', dynamicTheme.secondaryFont);
    document.documentElement.style.setProperty('--alternative-font-family', dynamicTheme.alternativeFont);
  }, [dynamicTheme]);
  
  // Providing the dynamic theme context to child components
  return (
    <ThemeContext.Provider value={dynamicTheme}>
        {children}
    </ThemeContext.Provider>
  );
}
