import axios from 'axios';
import { useJobId } from '../contexts/ContextJbo';
import { useContext } from "react";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { UserContext } from '../contexts/UserContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { BASE_URL } from './config';


/**
 * Custom hook to create an Axios instance with specific configurations and interceptors.
 * It leverages contexts for job ID and currency to enrich each request with additional data.
 *
 * @returns {AxiosInstance} - The configured Axios instance.
 */
export function useAxios() {
  // Accessing job ID and currency from their respective contexts.
  // for testing
  const { jboId } = useJobId();
  const theme = useContext(ThemeContext);
  const { userId } = useContext(UserContext);
  const { currentCurrency } = useContext(CurrencyContext); 

  // Creating an Axios instance with a predefined base URL and headers.
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 60000, // Timeout in milliseconds (60s)
  });

  // Interceptor to modify every outgoing request.
  instance.interceptors.request.use((config) => {
    // If config.data is already a string, we avoid stringifying it again.
    if (typeof config.data !== 'string') {
      // Adding job ID and currency information to the request's payload.
      config.data = {
        data: JSON.stringify({
          ...config.data,
          client_id: jboId,
          jbo_id: jboId,
          // userId modifications
          user_id: userId ? userId :"",
          currency: currentCurrency,
        }),
      };
    }

    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Retry logic for 502 errors (up to two retries)
  instance.interceptors.response.use(response => {
    // If the response is successful, return it
    return response;
  }, async (error) => {
    const config = error.config;

    // Set the retry count if it's not already set
    if (!config.__retryCount) {
      config.__retryCount = 0;
    }

    // Check if it's a 502 error and retry count is less than 2
    if (error.response && error.response.status === 502 && config.__retryCount < 2) {
      config.__retryCount += 1; // Increment the retry count

      try {
        // Retry the request with the same payload
        const retryResponse = await new Promise((resolve) => {
          setTimeout(() => {
            resolve(instance(config));
          }, 1000); // Add a delay before retrying (1 second)
        });

        // If the retry is successful, return the response without a second retry
        return retryResponse;
      } catch (retryError) {
        return Promise.reject(retryError);
      }
    }

    // If the retry limit has been reached or another error occurred, reject the request
    return Promise.reject(error);
  });

  return instance;
}
